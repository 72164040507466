import { imprintsConstants } from '../constants/imprints';

export function imprints(state = {imprint: {}, card: {}, autoCompleteLoading: false }, action) {
    switch (action.type) {
        case imprintsConstants.GET_IMPRINTS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case imprintsConstants.GET_IMPRINTS_SUCCESS:
            return {

                ...state,
                loading: false,
                items: action.imprints,
                metadata: action.metadata,
                total: action.total
            };
        case imprintsConstants.GET_IMPRINTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case imprintsConstants.GET_IMPRINTS_AUTOCOMPLETE_REQUEST:
            return {
                ...state,
                autocomplete_items: [],
                autoCompleteLoading: true
            };
        case imprintsConstants.GET_IMPRINTS_AUTOCOMPLETE_SUCCESS:
            return {

                ...state,
                autoCompleteLoading: false,
                autocomplete_items: action.imprints
            };
        case imprintsConstants.GET_IMPRINTS_AUTOCOMPLETE_FAILURE:
            return {
                ...state,
                autoCompleteLoading: false,
                error: action.error
            };

        case imprintsConstants.GET_TOP_IMPRINTS_REQUEST:
            return {
                ...state,
                topLoading: true,
                top: null,
                topTotal: 0,
                metadata: null,
                timeseries: null
            };
        case imprintsConstants.GET_TOP_IMPRINTS_SUCCESS:
            return {

                ...state,
                topLoading: false,
                top: action.imprints,
                topTotal: action.total,
                metadata: action.metadata
            };
        case imprintsConstants.GET_TOP_IMPRINTS_FAILURE:
            return {
                ...state,
                topLoading: false,
                error: action.error
            };

        case imprintsConstants.GET_IMPRINT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case imprintsConstants.GET_IMPRINT_DETAILS_SUCCESS:
            let imprint = state.imprint;
            imprint[action.id] = action.imprint;
            return {
                ...state,
                loading: false,
                imprint: imprint
            };
        case imprintsConstants.GET_IMPRINT_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case imprintsConstants.GET_IMPRINTS_CARD_REQUEST:
            return {
                ...state,
                cardLoading: true
            };
        case imprintsConstants.GET_IMPRINTS_CARD_SUCCESS:
            let card = state.card;
            card[action.id] = action.card;
            return {
                ...state,
                cardLoading: false,
                card: card
            };
        case imprintsConstants.GET_IMPRINTS_CARD_FAILURE:
            return {
                ...state,
                cardLoading: false,
                error: action.error
            };

        case imprintsConstants.GET_STREAMS_STATS_REQUEST:
            return {
                ...state,
                streamsLoading: true
            };
        case imprintsConstants.GET_STREAMS_STATS_SUCCESS:
            return {
                ...state,
                streamsLoading: false,
                streams: action.imprints
            };
        case imprintsConstants.GET_STREAMS_STATS_FAILURE:
            return {
                ...state,
                streamsLoading: false,
                error: action.error
            };

        case imprintsConstants.GET_TIMESERIES_IMPRINTS_REQUEST:
            return {
                ...state,
                timeseriesLoading: true,
                timeseries: null
            };
        case imprintsConstants.GET_TIMESERIES_IMPRINTS_SUCCESS:
            return {

                ...state,
                timeseriesLoading: false,
                timeseries: action.imprints
            };
        case imprintsConstants.GET_TIMESERIES_IMPRINTS_FAILURE:
            return {
                ...state,
                timeseriesLoading: false,
                error: action.error
            };

        default:
            return state
    }
}
