import moment from 'moment';
import { find, minBy, uniq, map } from 'lodash';
import vendors from '../../components/widgets/filter/enums/vendors';
import contentTypes from '../../components/widgets/filter/enums/content_types';
import { heartbeatsConstants } from '../constants/heartbeats';
import { convertToThousands } from '../../helpers/NumberFormatter';
import countriesByAbbreviation from '../../components/common/CountriesByAbbreviation';


export const heartbeatsFormatter = {
    formatWeeks,
    formatPagedHeartbeats
};

function formatWeeks(response) {
    return response.data.map(row=>({
        startDate: row.period_2_start,
        endDate: row.period_2_end,
        weekNumber: row.period_2_week,
        prevStartDate: row.period_1_start,
        prevEndDate: row.period_1_end,
    }));
}

function formatPagedHeartbeats(response, params, nosedives = false) {
    const vendor = find(params.filtered, param => param.id == 'vend_id');
    const biggestMetrics = nosedives ? 'period_1_units' : 'period_2_units';
    response.data = response.data.map(row=>{
        let biggestShare = 0, 
            biggestShareVendor = null,
            biggestShareTerritory = 'N/A';
        
        row.vendors = JSON.parse(row.vendors);
        
        let vendorSum = {};
        const uniqueVendors = uniq(map(row.vendors, 'vend_id'));
        const grandTotal = row.period_2_units;

        for(let vendor of row.vendors) {
            const vendorData = find(vendors, vend=>vend.vend_id == vendor.vend_id);
            vendor.vendor = vendorData ? vendorData.label : 'N/A';
            const countryData = find(countriesByAbbreviation, country=>country.abbreviation == vendor.territory);
            vendor.territory_name = countryData ? countryData.country : 'N/A';
            const contentTypeData = find(contentTypes, contentType=>contentType.value == vendor.content_type);
            vendor.content = contentTypeData ? contentTypeData.label : 'N/A';
            
            if(!vendorSum.hasOwnProperty(vendor.vend_id))
                vendorSum[vendor.vend_id] = 0;
            vendorSum[vendor.vend_id] += vendor.period_2_units;    
            
            if(vendor[biggestMetrics]> biggestShare) {
                biggestShare = vendor[biggestMetrics];
                biggestShareVendor = vendor.vend_id;
                biggestShareTerritory = vendor.territory;
            }
        }
        
        for(let vendorId of uniqueVendors) {
            for(let vendorRow of row.vendors) {
                if(!vendorRow.vend_id == vendorId)
                    continue;
                    
                vendorRow.share = grandTotal ? Math.round(vendorSum[vendorRow.vend_id] / grandTotal * 100) : 0;
                vendorRow.vendor_display = `${vendorRow.vendor} (${vendorRow.share}%)`;
                vendorRow.territory_share = vendorSum[vendorRow.vend_id] ? Math.round(vendorRow.period_2_units / vendorSum[vendorRow.vend_id] * 100) : 0
            }
        }
        
        /*

        if(vendor && vendor.value) {
            const vendorData = find(row.vendors, vendorItem=>vendorItem.vend_id == vendor.value);
            biggestShare = row[biggestMetrics];
            biggestShareVendor = vendor.value;
            biggestShareTerritory = vendorData ? vendorData.territory : null;
        }
        */

        const tiktokCreates = find(row.vendors, vendor=>(vendor.vend_id == 17 && vendor.content_type == 7));
        const tiktokViews = find(row.vendors, vendor=>(vendor.vend_id == 17 && vendor.content_type == 8));
        //row.tiktok_creates = tiktokCreates ? tiktokCreates.period_2_units : 0;
        //row.tiktok_views = tiktokCreates ? tiktokViews.period_2_units : 0;
        //row.tiktok_creates_ratio = row.tiktok_2_creates ? Math.round(row.tiktok_2_creates / row.period_2_units * 100) : 0;
        //row.tiktok_views_ratio = row.tiktok_2_views ? Math.round(row.tiktok_2_views / row.period_2_units * 100) : 0;
		row.biggest_mover = 'N/A';
		row.biggest_mover_share = 0;

        let biggestShareTerritoryName = '';
            
        if(biggestShareTerritory) {
            const territory = find(countriesByAbbreviation, {abbreviation: biggestShareTerritory});
            biggestShareTerritoryName = territory ? territory.country : 'Unknown';                
        }
        else {
            biggestShareTerritory = biggestShareTerritoryName = 'N/A';
        }

        
        const biggestMover = find(vendors, vendor=>vendor.vend_id == biggestShareVendor);
        if(biggestMover) {
            row.biggest_mover = biggestMover.label;
            row.biggest_mover_share = row[biggestMetrics] ? Math.round(biggestShare / row[biggestMetrics] * 100) : 0;
            row.biggest_mover_territory = biggestShareTerritory;
            row.biggest_mover_territory_name = biggestShareTerritoryName;
        }
        
        let releaseDate = row.first_release_date; ;
        if(!releaseDate)
            releaseDate = minBy(row.associated_releases, 'release_date'); 
        
        releaseDate = moment(releaseDate).format('YYYY-MM-DD');

        
        row.PDFHeader = `${row.title_and_version} by ${row.artist_name}`;
        row.PDFGrowth = `${convertToThousands(row.percent_change)}% (${convertToThousands(row.period_1_units)} / ${convertToThousands(row[biggestMetrics])})`;
        row.PDFReleaseDate = `${releaseDate} (${(row.new_release ? 'Y': 'N')})`;
        row.PDFBiggestMover = `${row.biggest_mover} (${row.biggest_mover_share}%)`;
        return row;
    });
    
    return response;
}