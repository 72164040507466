import { flatten, sortBy, map, filter } from 'lodash';

const blacklistPermissions = ['allow_admin', 'can_grant_access'];

export const usersFormatter = {
    formatUsers,
    formatUser,
    formatNewUser,
    formatDeleteUser,
    formatImprints,
    formatArtists,
    formatProducts,
    formatCampaigns
};

function formatUsers(data, onlySaved) {
    let users = data.data;
    if(onlySaved) {
        users = users.filter(user=>user.acl_saving == false)
    }
    return users;
}

function formatUser(data) {
    let user = data.data;    
    user.allow_api = Boolean(user.api_token);
    const dataSilosOrder = user.data_silo_codes.split(',');
    user.users_silos = sortBy(user.users_silos, (silo) => dataSilosOrder.indexOf(silo.silo));
    return user;
}

function formatNewUser(user, defaultImprints, currentUser) {
    
    let acl = Object.assign({}, user.acl);
    
    //
    /*
    if(!acl['imprint_ids'].length) {
        let defaultImprintIDs = {},
            defaultACLImprints = [];
        
        for(let imprint of defaultImprints) {
            const { id, label_id } = imprint;
            if(defaultImprintIDs[label_id]!==undefined)
                defaultImprintIDs[label_id].push(id);
            else
                defaultImprintIDs[label_id] = [id];
        }
        
        for( let label of Object.keys(defaultImprintIDs)) {
            defaultACLImprints.push({[label]: defaultImprintIDs[label]});
        }
        
        acl['imprint_ids'] = defaultACLImprints;
    }
    */    
    
    //
    let currentImprints = [];
    
    for(let parentIndex in acl['imprint_ids']) {
        let parent = acl['imprint_ids'][parentIndex]; 
        for (let parentID of Object.keys(parent)) {
            const entity = parent[parentID];
            if(entity && entity.length) {
                for(let entityID of entity) {
                    currentImprints.push(entityID)
                }
            }
            else {
                acl['imprint_ids'].splice(parentIndex, 1);
            }
                
        }
    }
    
    for(let entity of ['artist_ids', 'product_ids']) {
        for(let imprintID of currentImprints){
            let imprintFound = false;
            for(let parent of acl[entity]) {
                for (let parentID of Object.keys(parent)) {
                    if(parentID == imprintID)
                        imprintFound = true;
                }
            }
            
            if(!imprintFound) {
                acl[entity].push({[imprintID]: ["*"]})
            }
        }
    }
    
    //
    
    if(!acl['campaign_ids'] || acl['campaign_ids'].length == 0)
        acl['campaign_ids'] = [{0:['*']}];
    
    //
    
    for(let key of Object.keys(acl)) {
        acl[key] = JSON.stringify(acl[key])
    }
    
    for(let permission of blacklistPermissions) {
        user[permission] = false;
    }
    
    user.default_filter_id = user.default_filter;
    delete user.default_filter;

    // sort invitee's silo codes 
    const invitersSilos = currentUser.data_silo_codes.split(',');
    user.users_silos = sortBy(user.users_silos, (silo)=>invitersSilos.indexOf(silo.silo));
    //
    
    return {
        acl,
        user
    }
}

function formatDeleteUser(invitee_email) {
    return {invitee_email};
}

function addAlphabeticalList(data, nameKey) {
    return data.map(item=>{
        item.name = item[nameKey]; 
        item.letter = item.name.charAt(0).toUpperCase(); 
        return item;
    })
}

function addAlphabeticalListProduct(data, nameKey) {
    return data.map(item=>{
        item.name = `${item[nameKey]} (${item.barcode})`; 
        item.letter = item.name.charAt(0).toUpperCase(); 
        return item;
    })
}


function formatImprints({data}, user) {
    //const allowedSilos = map(filter(user.users_silos, (silo)=>(silo.can_grant_access || silo.client_admin)), 'silo');
    for(let item of data) {
        item.silos = item.silo_codes.split(',');
    }
    //data = filter(data, (item)=>allowedSilos.includes(item.silo_codes));
    return addAlphabeticalList(data, 'name');
}

function formatArtists({data}) {
    
    return sortBy(data.map(item=>{
        for(let artist of item.artists) {
            artist.silos = artist.data_silo_codes ? artist.data_silo_codes.split(',') : []
        }
        item.artists = addAlphabeticalList(item.artists, 'name');
        return item;
    }), ['name']);
}

function formatProducts({data}, imprintID) {
    return sortBy(data.map(item=>{
        item.products = addAlphabeticalListProduct(item.products, 'title');
        item.imprintID = imprintID;
        return item;
    }), ['name']);
}

function formatCampaigns(data){
    return addAlphabeticalList(data, 'title');
}
