import { map, find, uniqBy, sortBy, uniq, fill, sumBy, capitalize } from 'lodash'
import moment from 'moment';
import rand from '../../helpers/rand';
import ImageURL from '../../helpers/ImageURL';
import icons from '../../helpers/Icons';
import { colors } from '../../components/widgets/charts/ChartDefaults';
import vendorsArray from '../../components/widgets/filter/enums/vendors';
import { formatChartLabel, formatChartLabelItem, sortDateRange, getWeekDay, getWeekDayWarning } from '../../helpers/DateFormatter';
import {dateRange, formattedDateRange, dateRangeFlat} from '../../helpers/DateRange';
import {percentageValue} from '../../helpers/NumberFormatter';
import AddTotal from '../../components/common/Datagrid/AddTotal';
import VendorHelper from '../../components/common/Datagrid/VendorHelper';

export const productsFormatter = {
    formatForPercentageChart,
    formatForTable,
    formatTop,
    formatDetails,
    formatForTableOld,
    formatAutocomplete,
    formatAutocompleteItem,
    formatCard,
    formatStreamsStats,
    formatStreamsStatsByIDs,
    formatTracks,
    formatMetadata,
    formatMetadataFlat,
    formatMetadataBarcodesFlat,
    formatMetadataByIDs,
    formatTimeseries,
    formatCompareProducts,
    formatCompareProductDemographics,
    formatCompareProductTerritories,
    formatCompareProductDatetime,
    formatCompareProductVendors,
    formatCompareProductArtist,
    formatProductsReleaseDates,
	formatProductsByBarcode,
    formatOrderedTracks
};

function formatForPercentageChart(data, column){
    let dataset = [], 
        labels = [];
    
    for(let entry of data) {
        dataset.push(entry[column]);
        labels.push(entry.name);
    }
    return {labels, datasets:[{data: dataset, label: 'Streams'}]};
}

function formatForTable(data){
    if(!data)
        return {data: [], total: 0};
    let total = 0;
    
    for(let entry of data) {
        total = entry.total_entities;
        entry.id = entry.product_id;
        entry.name = entry.product_title;
        entry.logo = ImageURL(entry.product_image, entry.id, 'products');
        if(entry.active && entry.curr_units)
            entry.engaged = Math.round((entry.curr_active / entry.curr_units) * 100);
    }
    //data = AddTotal(data, 'product');
    return {data, total};
}

function formatTop(data){
    if(!data)
        return [];  
    let total = 0; 
    data = map(data, (entry)=>{
        total = entry.total_entities;
        entry.id = entry.product_id;
        //entry.name = entry.product_title+(entry.product_version ? ` (${entry.product_version})` : '');
        entry.code = entry.upc;
        //entry.image = ImageURL(entry.product_image, entry.id, 'products');
        entry.absoluteValue = entry.curr_units;
        entry.percentageValue = entry.units_diff = percentageValue(entry.curr_units, entry.prev_units);
        entry.playlists_diff = percentageValue(entry.curr_playlists, entry.prev_playlists);
        entry.engaged = entry.curr_units ? Math.round((entry.active / entry.curr_units) * 100) : 0;
        entry.vendors = VendorHelper.VendorColors(entry.vendors);
        entry.units_change = entry.prev_units ? entry.curr_units - entry.prev_units : 0;
        return entry;
    });
    return {data, total};
}

function formatDetails(data){
    if(!data)
        return [];

    let labels = [],
        dataset = [];
    
    data = data.sort(sortDateRange);
    
    for(let item of data) {
        labels.push(formatChartLabel(item));
        dataset.push(item.units);
    }
    return {labels, datasets: [{data: Object.values(dataset), label: 'plays'}]};
}

function formatForTableOld(data){
    if(!data)
        return [];
    for(let entry of data.data) {
        entry.name = entry.title;
        entry.logo = entry.image;
    }
    return data;
}

function _formatAutocompleteItem(hit){
    if(hit.entity)
        return hit;
    
    const entity = 'products',
        name = hit.name? hit.name : hit._source.title,
        artist = hit.artist_name? hit.artist_name : hit._source.artist,
        id = hit.id ? hit.id : hit._id,
        barcode = hit._source.barcode ? `(${hit._source.barcode}) ` : '';

    return {
        id,
        entity,
        name: `${name} ${barcode}(product by ${artist})`,
        logo: icons.entities[entity],
        image: ImageURL(hit.packshot_file_name, hit.id, 'products')
    };
}

function formatAutocompleteItem(hit){
    const entity = 'products';
    const artist = hit.display_artist ? hit.display_artist : ( hit.artist_name ? hit.artist_name : '' );
        
    return {
        id: hit.id,
        entity,
        code: hit.barcode,
        name: `${hit.title}${hit.version ? ' '+ hit.version : ''} ${hit.barcode} (product${artist? ' by ' + artist : ''})`,
        name_compare: `${artist} - ${hit.title}`,
        name_raw: hit.title,
        name_result: `${artist} - ${hit.title} (${hit.barcode}) released on ${hit.original_release_date} (${getWeekDay(hit.original_release_date)})`,
        logo: icons.entities[entity],
        image: ImageURL(hit.packshot_file_name, hit.id, 'products'),
        first_released: hit.release_date,
        week_day_warning: getWeekDayWarning(hit.release_date)
    }
}


function formatAutocomplete(data) {
    let {products}= data;
    return products.map(formatAutocompleteItem);
}

function formatCard(data) {
    data = data.data;
    return {
      id: data.id,
      name: `${data.title} (product)`,
      name_raw: `${data.title}`,
      code: data.barcode,
      logo: ImageURL(data.packshot_file_name, data.id, 'products'),
      primary_artist: data.primary_artist,
      release_date: data.release_date
    };
}

function formatStreamsStats(streams, metadata) {
    let labels = [],
        datasets = [],
        datasetPeriod = '';
    if(streams && metadata) {
        const {dates, period} = dateRangeFlat(streams);
        datasetPeriod = period;
        datasets = metadata.map((productEntry, index)=>{
            let product = productEntry.product;
            let data = [];
            for(let date of dates) {
                let stream = find(streams, {stream_date: date, product_id: product.id});
                data.push(stream ? stream.curr_units : null);
            }    

            return {
                id: product.id,
                label: `${product.title} (${product.barcode}) (product)`,
                data,
                fill: false,
                borderColor: colors[index%colors.length],
                backgroundColor: colors[index%colors.length],
                pointBorderColor: colors[index%colors.length],
                pointBackgroundColor: colors[index%colors.length],                

            }
        });    
        
        labels =  map(dates, (date)=>formatChartLabelItem(date, period));
    }
    return { labels, datasets, datasetPeriod };
}

function formatStreamsStatsByIDs(streams, metadata) {
    let labels = [],
        datasets = [];
    if(streams && metadata) {
        const {dates, period} = dateRangeFlat(streams);
        datasets = metadata.map((product, index)=>{
            let data = [];
            for(let date of dates) {
                let stream = find(streams, {stream_date: date, product_id: product.id});
                data.push(stream ? stream.curr_units : null);
            }    

            return {
                id: product.id,
                label: `${product.title} (${product.barcode}) (product)`,
                data,
                fill: false,
                borderColor: colors[index%colors.length],
                backgroundColor: colors[index%colors.length],
                pointBorderColor: colors[index%colors.length],
                pointBackgroundColor: colors[index%colors.length],                

            }
        });    
        
        labels =  map(dates, (date)=>formatChartLabelItem(date, period));
    }
    return { labels, datasets };
}

function formatTracks(products) {
    let tracks = [];
    for (let product of products) {
        for (let track of product.tracks) { 
            tracks.push({
                internal_id: track.id,
                value: track.isrc,
                label: `${track.title} (${track.isrc}) (from ${product.title})`
            })
        }
    }
    return tracks;
}

function formatMetadata(entities, metadata) {
    for(let entity of entities) {
        const metaEntity = find(metadata, meta=>meta.product.id==entity.id);
        if(metaEntity) {
            //const artist = metaEntity.artists ? metaEntity.artists[0].artist : {};
            const artist = metaEntity.product.primary_artist;
            const displayArtist = metaEntity.product.display_artist;
            
            entity.name = entity.product_title = metaEntity.product.title;
            entity.product_version = metaEntity.product.version;
            entity.product_genre = metaEntity.product.primary_genre;
            entity.original_release_date = metaEntity.product.original_release_date;
            if(!entity.release_date)
                entity.release_date = metaEntity.product.release_date;
            
            entity.imprint_name = metaEntity.imprint_name;
            
            
            entity.image = ImageURL(metaEntity.product.packshot_file_name, entity.id, 'products');
            entity.artist_id = artist ? artist.id : null;
            entity.artist_name = displayArtist ? displayArtist: (artist ? artist.name : 'N/A');
        }
    }
    return entities;

}

function formatMetadataFlat(metadata) {
    let result = []
    for(let row of metadata) {
        result.push({
            id: row.id,
            entity: 'products',
            name: row.title,
            code: row.barcode,
            image: ImageURL(row.packshot_file_name, row.id, 'products'),
            primary_artist: row.primary_artist,
            releaseDate: row.release_date
        });
    }
    return result;
}

function formatMetadataBarcodesFlat(metadata) {
    let result = []
    for(let row of metadata) {
        const { product } = row;
        result.push({
            id: row.id,
            entity: 'products',
            name: product.title,
            code: product.barcode,
            image: ImageURL(product.packshot_file_name, product.id, 'products'),
            primary_artist: product.primary_artist ? product.primary_artist.name : 'N/A',
            releaseDate: product.release_date
        });
    }
    return result;
}

function formatMetadataByIDs(entities, metadata) {
    //todo
    for(let entity of entities) {
        const metaEntity = find(metadata, meta=>meta.product.id==entity.id);
        if(metaEntity) {
            const artist = metaEntity.artists ? metaEntity.artists[0].artist : {};
            
            entity.name = entity.product_title = metaEntity.product.title;
            entity.product_version = metaEntity.product.version;
            entity.product_genre = metaEntity.product.primary_genre;
            entity.original_release_date = metaEntity.product.original_release_date;
            entity.release_date = metaEntity.product.release_date;
            entity.imprint_name = metaEntity.imprint_name;
            
            
            entity.image = ImageURL(metaEntity.product.packshot_file_name, entity.id, 'products');
            entity.artist_id = artist.id;
            entity.artist_name = artist.name;
        }
    }
    return entities;

}


function formatTimeseries(products, metadata) {
    for(let product of products) {
        
        const metaEntity = find(metadata, meta=>meta.product.id==product.product_id);
        if(metaEntity) {
            product.name = `${metaEntity.product.title} (${metaEntity.product.barcode})`;
        }
        product.stms_by_date = product.totals;
    }
        
    return products;
}

function formatCompareProducts(idsArray, data) {
    const idsArraySorted = idsArray.split(',');
    
    const ids = sortBy(uniq(map(data, 'upc')), (id)=>idsArraySorted.indexOf(id)),
        weeks = uniq(map(data, 'weeks_since_release')).sort(),
        weeksCount = weeks.length,
        sources = ['album', 'artist', 'collection', 'other', 'playlist', 'radio', 'search'];
    
    let result = {},
        sourceResult = {};
    
    for(let id of ids) {
        result[id] = {
            streams: fill(Array(weeksCount), null),
            listeners: fill(Array(weeksCount), null),
            ratio: fill(Array(weeksCount), null),
            active: fill(Array(weeksCount), null),
            skipped: fill(Array(weeksCount), null)
        }
        let sourceItem = {};
        for(let source of sources) {
            sourceItem[source] = fill(Array(weeksCount), null);
        }
        sourceResult[id] = sourceItem; 
    }
    for(let item of data) {
        const {upc, weeks_since_release: week, total_streams, listeners, listener_ratio, active, skipped} = item;
        result[upc].streams[week] = total_streams;
        result[upc].listeners[week] = listeners;
        result[upc].ratio[week] = listener_ratio;
        result[upc].active[week] = total_streams ? Math.round(active / total_streams * 100) : 0;
        result[upc].skipped[week] = total_streams ? Math.round(skipped / total_streams * 100) : 0;
        
        let totalSources = 0,
            maxSourceKey = '',
            maxSource = 0;
        
        for(let source of sources) {
            const sourcePercentage = item[`src_${source}`];
            sourceResult[upc][source][week] = sourcePercentage;
            totalSources += Math.round(sourcePercentage * 100);
            if(maxSource < sourcePercentage) {
                maxSource = sourcePercentage;
                maxSourceKey = source;
            }
        }
        
    }
    //const sourceLabels = sources.map(source => capitalize(source));    
    return {datasets: result, labels: weeks, sourceDatasets: sourceResult, sourceLabels: sources, data};
}

function formatCompareProductDemographics(data) {
    if(!data || !data.length)
        return null;
    
    for(let item of data) {
        let [gender, age] = item.demographic.split(' ');
        item.age = age;
        item.gender = gender;
    }
    
    const genderOrder = ['Male', 'Female', 'Unknown'];
    
    let genders = uniq(map(data, 'gender')),
        ages = uniq(map(data, 'age')),
        datasets = {},
        totalStreams = sumBy(data, 'curr_units');
    
    ages.sort();
    genders = sortBy(genders, (gender) => genderOrder.indexOf(gender));

    for(let gender of genders) {
        datasets[gender] = [];
    }
    
    for(let entry of data) {
        datasets[entry.gender][ages.indexOf(entry.age)] = entry.curr_units;
        entry.share = totalStreams ? Math.round(entry.curr_units / totalStreams * 100) : 0;
    }
    
    datasets = map(datasets, (data, label)=>{
        return {
            data, 
            label, 
        };
    });


    
    return {labels: ages, datasets, data};

}

function formatProductsReleaseDates(products, ids) {
    
    let results = [];
    for(let product of products.products) {
        let tracks = product.tracks;
        product = product.product;
        let result = {
            id: product.id,
            code: product.barcode,
            release_date: product.release_date,
            name_result: `${product.primary_artist.name} - ${product.title} (${product.barcode}) released on ${product.release_date} (${getWeekDay(product.release_date)}). Contains ${tracks.length} tracks.`,
            name_compare: `${product.title} - ${product.primary_artist.name}`,
            week_day_warning: getWeekDayWarning(product.release_date),
            primary_artist: product.primary_artist.name,
            primary_artist_id: product.primary_artist.id
            
        }
        
        
        results.push(result);
    }
    const idsArraySorted = ids.split(',');
    results = sortBy(results, (result) => idsArraySorted.indexOf(result.code))
    return results;
}

function formatCompareProductTerritories(data) {
    for(let itemIndex in data) {
        let item = data[itemIndex];
        item.code = item.territory;
        item.name = item.territory_name;
        item.share = item.total_units ? Math.round((item.curr_units / item.total_units) * 100) : 0;
        item.rank = ++itemIndex;
    }
    return data;
}

function formatCompareProductVendors(data) {
    if(!data || !data.length)
        return null;
    
    let datasets = [];
    const weeks = uniq(map(data, 'weeks_since_release')).sort(),
        contentTypes = uniq(map(data, 'content_type')).sort(),
        vendors = uniq(map(data, 'vendor')).sort();
    
    for(let vendorID in vendors) {
        let vendor = vendors[vendorID];
        for(let contentType of contentTypes) {
            const vendorMeta = find(vendorsArray, (vendorItem)=>vendorItem.label == vendor);
            
            const streams = data.filter((item)=>(item.vendor == vendor && item.content_type == contentType));
            if(streams.length) {
                let color = colors[vendorID];
                if(vendorMeta && vendorMeta.hex[contentType])
                    color = vendorMeta.hex[contentType];
                    
                let dataset = fill(Array(weeks.length), 0);
                
                for(let stream of streams) {
                    dataset[stream.weeks_since_release] = stream.curr_units;
                }
                datasets.push({
                    id: `${vendor}_${contentType}`,
                    label: `${vendor} (${contentType})`,
                    data: dataset,
                    source: contentType,
                    borderColor: color,
                    backgroundColor: color,
                    pointBorderColor: color,
                    pointBackgroundColor: color            
                })
                
            }
        }

        
        
        
    }
    return {labels: weeks, datasets, data};
}

function formatCompareProductArtist(data) {
    const entities = uniq(map(data, 'entity_name')).sort(),
        weeks = uniq(map(data, 'weeks_since_release')).sort();
    
    let datasets = [];
    for(let entity of entities) {
        let dataset = [];
        for(let week of weeks) {
            let stream = find(data, item=>(item.entity_name == entity && item.weeks_since_release == week));
            dataset.push(stream ? stream.curr_units : null)
        }
        datasets.push({data: dataset, label: capitalize(entity)});
    }    
    return {labels: weeks, datasets, data};
}

function formatProductsByBarcode(products) {
	return products.map(product=>{
		let {product: hit, artists} = product;
		let artist = artists.length ? artists[0].artist : {name: null};
		let title = `${hit.title}${hit.version ? ' '+ hit.version : ''} ${hit.barcode} (product${artist.name ? ' by ' + artist.name : ''})`;
		return { barcode: hit.barcode, title: title };	
	})
}

function formatOrderedTracks({products}) {
    let tracks = [], releaseDate = '';
    if(Array.isArray(products) && products.length) {
        tracks = products[0].tracks;
        releaseDate = products[0].release_date;
        tracks = sortBy(tracks, ['disk', 'position'])
    }
    return { tracks, releaseDate };
}

function formatCompareProductDatetime(data) {
    const weekdays = uniq(map(data, 'weekday')).sort();
    const labels = weekdays.map(day => moment().weekday(day).format('ddd'));
    let datasets = [];
    const sums = {};

    data.forEach(entry => {
        if (!sums.hasOwnProperty(entry.weekday)) {
            sums[entry.weekday] = 0;
        }
        sums[entry.weekday] += entry.units;
    });

    data.forEach(entry => {
        entry.units = sums[entry.weekday] ? Math.round(entry.units / sums[entry.weekday] * 100) : 0;
        if (entry.units >= 0 && entry.units < 9) {
            entry.backgroundColor = `rgba(150,87,195,${entry.units / 10})`;
        } else {
            entry.backgroundColor = 'rgba(150,87,195,1)';
        }
    });
    
    const sortedHours = uniq(map(data, 'hour')).sort((a, b) => b - a);
    
    for (let hour of sortedHours) {
        let days = data.filter(row=>row.hour == hour);
        days = sortBy(days, 'weekday'); 
        let row = days.map(day=>day.backgroundColor);
        let rowValue = days.map(day=>day.units);
        datasets.push({
            label: hour,
            data: Array(7).fill(2),
            backgroundColor: row,
            value: rowValue,
        })
    }

    return {labels, datasets};
} 