//import $ from 'jquery';
import Param from '../../helpers/Param';
import AuthHeader from '../../helpers/AuthHeader';
import BuildQS from '../../helpers/BuildQS';
import BuildFilterQS from '../../helpers/BuildFilterQS';
import config from '../../config/config';


export const playlistsService = {
    getPlaylists,
    getTopPlaylists,
    getPlaylistStats,
    getMetadata,
    getTimeseriesPlaylists,
    getPlaylistShare,
    getPlaylistTypesSources,
	getPlaylistTypesSourcesDiscovery,
    getPlaylistBenchmarks,
    getPlaylistsAutocomplete,
    getPlaylistsAutocompleteBatch
};

function getPlaylists(params, globalFilter, mode, fields){
    return config.live ? getPlaylistsNew(params, globalFilter, mode, fields) : ( mode == 'main' ? getPlaylistsStatic(params) : getPlaylistsTotalStatic(params) );
}

function getPlaylistsNew(params, globalFilter, mode = 'main', fields) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'})

        };

    let queryName = 'playlist_library_search';
    qs.mode = mode;
    qs.offset = params.page ? params.page*globalFilter.limit : 0;

    let {sorted, filtered, mode: limitMode } = params;
    if(sorted !== undefined){
        sorted = sorted[0];
        qs.order_by = sorted.id;
        qs.order_dir = sorted.desc ? 'desc':'asc';
    }

    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
                
            if(filter.id == 'playlists') {
                queryName = 'playlist_library_search_id';
            }

            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }
        //
        if(!qs.search_term)
            delete qs.search_by;
        //
    }    
    
    qs.query_name = queryName;
    
    if(limitMode == 'inline')
        qs.force_limit = true;
    
    if(fields) {
        if(Array.isArray(fields))
            fields = fields.join(',');
        qs.fields = fields;
    }

    
    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
}

function getPlaylistsStatic(params) {
    const suffix = (params.mode == 'inline') ? '_inline' : ''; 
    let baseUrl = `${config.apis.sales}/api/static/playlist_search${suffix}.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function getPlaylistsTotalStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/playlist_totals.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}


function getTopPlaylists(params, globalFilter, fields){
    return true || config.live ? getTopPlaylistsLive(params, globalFilter, fields) : getTopPlaylistsStatic();
}

function getTopPlaylistsLive(params, globalFilter, fields) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'})

        };
        
    qs.query_name = 'top_playlists';
    qs.offset = params.page ? params.page*globalFilter.limit : 0;

    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    

    if(fields) {
        if(Array.isArray(fields))
            fields = fields.join(',');
        qs.fields = fields;
    }
    
    qs = BuildFilterQS(qs, 'playlists');
    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function getTopPlaylistsStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/top_playlists_simple.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getPlaylistStats(id, globalFilter){
    return true || config.live ? getPlaylistStatsLive(id, globalFilter) : getPlaylistStatsStatic();
}

function getPlaylistStatsStatic(mode) {
    let baseUrl = `${config.apis.sales}/api/static/playlist_details.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getPlaylistStatsLive(id, globalFilter) {
    let baseUrl = config.apis.playlist,
        requestOptions = {
            headers: AuthHeader({'Authorization': 'Basic '+window.btoa(config.credentials.playlist)})
        };
            
    baseUrl += `/playlists/int/${id}.json`;
    return fetch(baseUrl, requestOptions).then(res=>res.json());
}

function getMetadata(ids){
    return config.live ? getMetadataLive(ids) : getMetadataStatic();
}

function getMetadataLive(ids) {
    ids = encodeURIComponent(ids.join(','));
    
    let baseUrl = `${config.apis.playlist}/playlists/by_id.json`, //
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8', 'Authorization': 'Basic '+window.btoa(config.credentials.playlist)}),
            body: 'ids='+ids
        };
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getMetadataStatic() {
    let baseUrl = `${config.apis.sales}/api/static/playlists_metadata.json`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    });
}


function getTimeseriesPlaylists(params, globalFilter){
    return config.live ? getTimeseriesPlaylistsLive(params, globalFilter) : getTimeseriesPlaylistsStatic();
}

function getTimeseriesPlaylistsLive(params, globalFilter) {
    /*
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [{id:'ids', value:ids}]
    }
    */
    
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'})

        };
        
    qs.query_name = 'top_playlists';
    // qs.offset = 0;
    qs.mode = 'timeseries_ids';

    let {sorted, filtered, dateGroup} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    if(dateGroup) {
        qs.date_grouping = dateGroup;
    }

    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function getTimeseriesPlaylistsStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/top_playlists_timeseries.json`;
    return fetch(baseUrl).then(res=>res.json());
}


function getPlaylistShare(id, globalFilter){
    return config.live ? getPlaylistShareLive(id, globalFilter) : getPlaylistShareStatic();
}

function getPlaylistShareStatic(mode) {
    let baseUrl = `${config.apis.sales}/api/static/playlist_share.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getPlaylistShareLive(id, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'})
        };
    
    qs.query_name = 'playlist_share';
    qs.mode = 'main';
    qs.ids = id;
    
    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}


function getPlaylistTypesSources(params, globalFilter){
    return config.live ? getPlaylistTypesSourcesLive(params, globalFilter) : getPlaylistTypesSourcesStatic();
}

function getPlaylistTypesSourcesStatic(mode) {
    let baseUrl = `${config.apis.sales}/api/static/playlists_types_sources.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getPlaylistTypesSourcesLive(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'})
        };
    
    qs.query_name = 'playlists_types_sources';
    qs.mode = 'main';
    for(let param of Object.keys(params.filtered)) {
        let value = params.filtered[param];
        if(!value)
            continue;
        if(value instanceof Array) {
            value = value.join(',');
            if(!value)
                continue;
            qs[param] = value;
        }
        else {
            qs[param] = value;    
        }
    } 
    
    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getPlaylistTypesSourcesDiscovery(params, globalFilter){
    return config.live ? getPlaylistTypesSourcesDiscoveryLive(params, globalFilter) : getPlaylistTypesSourcesDiscoveryStatic();
}

function getPlaylistTypesSourcesDiscoveryStatic(mode) {
    let baseUrl = `${config.apis.sales}/api/static/playlists_types_sources.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getPlaylistTypesSourcesDiscoveryLive(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'})
        };
    
    qs.query_name = 'playlists_types_sources_discovery';
    qs.mode = 'main';
    for(let param of Object.keys(params.filtered)) {
        let value = params.filtered[param];
        if(!value)
            continue;
        if(value instanceof Array) {
            value = value.join(',');
            if(!value)
                continue;
            qs[param] = value;
        }
        else {
            qs[param] = value;    
        }
    }
    
    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getPlaylistBenchmarks(id, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'})
        };
    
    qs.query_name = 'playlist_benchmarks';
    qs.id = id;
    
    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getPlaylistsAutocomplete(query, currentUser) {
	const silo = currentUser.primary_silo_code;
    query = encodeURIComponent(query);
    let baseUrl = `${config.apis.playlist}/playlists/search?q=${query}&silo_code=${silo}`, //
        requestOptions = {
            method: 'GET',
            headers: AuthHeader({'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8', 'Authorization': 'Basic '+window.btoa(config.credentials.playlist)}),
        };
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getPlaylistsAutocompleteBatch(query, currentUser) {
    const silo = currentUser.primary_silo_code;
    
    let baseUrl = `${config.apis.playlist}/playlists/batch_search`, //
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json', 'Authorization': 'Basic '+window.btoa(config.credentials.playlist)}),
            body: JSON.stringify({search_input: query, silo_code: silo})
        };
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}