import React from 'react';
import { cloneDeep, get, find, uniq, map } from 'lodash';
import { pdf as PDFRenderer }from '@react-pdf/renderer';
import moment from 'moment';
import OverviewPDF from './PDFOverview';
import AudiencePDF from './PDFAudience';
import PlaylistsPDF from './PDFPlaylists';
import ProfilePDF from './PDFProfile';
import SocialPDF from './PDFSocial';
import {WithWidgetManager} from '../../widgets/manager'; 

export default function exportToPdf(page, reduxProps, exportProgress){
    let PDFData, PDFDocument
    switch(page){
        case 'overview':
            PDFData = exportToPdfOverview(reduxProps);
            PDFDocument = OverviewPDF;
        break;
        case 'audience':
            PDFData = exportToPdfAudience(reduxProps);
            PDFDocument = AudiencePDF;
        break;
        case 'playlists':
            PDFData = exportToPdfPlaylists(reduxProps);
            PDFDocument = PlaylistsPDF;
        break;        
        case 'profile':
            PDFData = exportToPdfProfile(reduxProps);
            PDFDocument = ProfilePDF;
        break;        
        case 'social':
            PDFData = exportToPdfSocial(reduxProps);
            PDFDocument = SocialPDF;
        break;        
        
    }
    
    const PDFWithManager = WithWidgetManager(PDFDocument, `result_${page}`);
    exportProgress(true);
    const data = PDFRenderer(<PDFWithManager data={PDFData} />);
    data.toBlob()
    .then(blob=>{
        exportProgress(false);
        const fileURL = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = getFileTitle(reduxProps, PDFData.reportTitle);
        link.click();
    });

} 

function exportToPdfOverview(reduxProps){
    const reportTitle = 'Overview',
        { imprints, artists, products, tracks, playlists, stats } = reduxProps;
            
    const imageIDs = [
          'streams_and_listeners',
          'home_vendors',
          'top_territories',
          'top_imprints',
          'top_products',
          'top_tracks',
          'top_playlists',
          'top_artists'
    ];
    let images = {};
    for (let imageID of imageIDs){
        let image = document.getElementById(imageID);
        if(image)
            images[imageID] = image.toDataURL("image/jpeg,1.0"); 
    }
    
    let vendorTableData = [];
    
    if (reduxProps.stats.vendor)
        vendorTableData = reduxProps.stats.vendor.table
    
    const tables = {
        imprints: imprints.top,
        artists: artists.top,
        products: products.top,
        tracks: tracks.top,
        territories: get(stats, 'territory.table', []),
        vendorsTable: vendorTableData
    };
    return { reportTitle, images, tables, header: getHeaderData(reduxProps, reportTitle) };

}

function exportToPdfAudience(reduxProps){
    const reportTitle = 'Audience',
        { audience, stats } = reduxProps;
    
    const imageIDs = [
        // 'audience_followers_line_chart',
        // 'audience_monthly_listeners_line_chart',
        'audience_streams_listeners',
        'audience_demographics_bar_chart',
        'audience_repeat_listeners_line_chart',
        'audience_tiktok_line_chart',
        'audience_sources_pie_chart',
        'audience_sources_discovery_pie_chart',
        'audience_devices_pie_chart',
    ];
    let images = {};
    for (let imageID of imageIDs){
    let image = document.getElementById(imageID);
    if(image)
        images[imageID] = image.toDataURL("image/jpeg,1.0"); 
    }
    const tables = {
        territories: audience.territory.table.slice(0, 10),
        //playlists: audience.playlist,
        sources: get(stats, 'sourcesStats.table'),
        sourcesDiscovery: get(stats, 'sourcesDiscoveryStats.table'),
        devices: stats.devices.table,
        demographics: cloneDeep(audience.demographics.table),
    }
    return { reportTitle, images, tables, header: getHeaderData(reduxProps, reportTitle) };

}


function exportToPdfPlaylists(reduxProps){
    const reportTitle = 'Playlists',
        { playlists, stats } = reduxProps;
    
    const imageIDs = [
          // 'top_territories_playlists',
          'top_playlists',
    ];
    let images = {};
    for (let imageID of imageIDs){
        let image = document.getElementById(imageID);
        if(image)
            images[imageID] = image.toDataURL("image/jpeg,1.0"); 
    }
    
    const tables = {
        playlists: playlists.items,
        // territories: stats.territory.table,
    };
    return { reportTitle, images, tables, header: getHeaderData(reduxProps, reportTitle) };

}

function exportToPdfProfile(reduxProps){
    const reportTitle = 'Profile',
        { artists, milestones, stats } = reduxProps;
    
    const tables = {
        socials: artists.socialItems,
        milestones: milestones.entities['mixed'].tableItems
    };
    return { reportTitle, tables, header: getHeaderData(reduxProps, reportTitle) };

}

function exportToPdfSocial(reduxProps){
    const reportTitle = 'Social Music',
        { tracks, stats } = reduxProps;
        
    let metrics = [];
    for(let track of tracks.tiktokMetrics.table) {
        let { vendors, ...rest } = track;
        let vendorRow = find(vendors, row=>row.vend_id == 0);
        let tableRow = {...rest, ...vendorRow};
        metrics.push(tableRow);
    }
    
    let territories = [], territoriesData = tracks.tiktokTerritories.result;
    let uniqueCountries = uniq(map(territoriesData, 'country'));
    for(let country of uniqueCountries) {
        const viewsTotal = find(territoriesData, territory=>(territory.country == country && territory.vendor == 0 && territory.contentType == 'views'));
        const createsTotal = find(territoriesData, territory=>(territory.country == country && territory.vendor == 0 && territory.contentType == 'creates'));
        territories.push({
            country, 
            views: viewsTotal.curr_units,
            prev_views: viewsTotal.curr_units - viewsTotal.prev_units,
            creates: createsTotal.curr_units,
            prev_creates: createsTotal.curr_units - viewsTotal.prev_units            
        })
    }
    
    
    const imageIDs = [
        'audience_tiktok_line_chart',
        'audience_tiktok_pie_chart',
        'audience_tiktok_territories_line_chart'
    ];
    let images = {};
    for (let imageID of imageIDs){
        let image = document.getElementById(imageID);
        if(image)
            images[imageID] = image.toDataURL("image/jpeg,1.0"); 
    }
    const tables = {
        metrics: metrics,
        territories
    }
    return { reportTitle, images, tables, header: getHeaderData(reduxProps, reportTitle) };

}


function getHeaderData(reduxProps, reportTitle) {
    return {
        overview: reduxProps.stats.top,
        globalFilter: reduxProps.filter.global,
        missingData: reduxProps.filter.logs,
        entityFilter: reduxProps.entityFilter,
        entityTitle: reduxProps.entityTitle,
        reportTitle
    }
}

function getFileTitle(reduxProps, reportTitle){
    const dateFormat = 'YYYYMMDD';
    const { dateStart, dateEnd } = reduxProps.filter.global;
    let { entityTitle } = reduxProps;
    if(entityTitle.includes('+'))
        entityTitle += ' more';
    
    return `${reportTitle} - ${entityTitle} - ${moment(dateStart).format(dateFormat)}-${moment(dateEnd).format(dateFormat)}.pdf`
}

