import { map, find, uniqBy, get } from 'lodash'
import moment from 'moment';
import store from '../../helpers/store';
import ImageURL from '../../helpers/ImageURL';
import SiloSuffix from '../../helpers/SiloSuffix';
import icons from '../../helpers/Icons';
import { formatChartLabel, formatChartLabelItem, sortDateRange } from '../../helpers/DateFormatter';
import { colors } from '../../components/widgets/charts/ChartDefaults';
import {dateRange, formattedDateRange, dateRangeFlat} from '../../helpers/DateRange';
import {percentageValue} from '../../helpers/NumberFormatter';
import AddTotal from '../../components/common/Datagrid/AddTotal';
import VendorHelper from '../../components/common/Datagrid/VendorHelper';

export const imprintsFormatter = {
    formatForPercentageChart,
    formatForTable,
    formatForTableOld,
    formatTop,
    formatAutocomplete,
    formatAutocompleteItem,
    formatDetails,
    formatCard,
    formatStreamsStats,
    formatMetadata,
    formatMetadataFlat,
    formatTimeseries
};

function formatForPercentageChart(data, column){
    let dataset = [], 
        labels = [];
    
    for(let entry of data) {
        dataset.push(entry[column]);
        labels.push(entry.name);
    }
    return {labels, datasets:[{data: dataset, label: 'Streams'}]};
}

function formatForTable(data){
    if(!data)
        return {data: [], total: 0};
    let total = 0;

    for(let entry of data) {
        total = entry.total_entities;
        entry.id = entry.imprint_id;
        entry.name = entry.imprint_name;
        entry.logo = ImageURL(entry.imprint_image, entry.id, 'imprints');
        if(entry.active && entry.curr_units)
            entry.engaged = Math.round((entry.active / entry.curr_units) * 100);
    }
    //data = AddTotal(data, 'imprint');
    return {data, total};
}

function formatForTableOld(data){
    if(!data)
        return [];
    for(let entry of data.data) {
        entry.logo = entry.logo_file_name;
    }
    return data;
}

function formatTop(data){
    if(!data)
        return [];    
    let total = 0;    
    data = map(data, (entry)=>{
        total = entry.total_entities;
        entry.id = entry.imprint_id;
        entry.absoluteValue = entry.curr_units;
        entry.percentageValue = entry.units_diff = percentageValue(entry.curr_units, entry.prev_units);
        entry.playlists_diff = percentageValue(entry.curr_playlists, entry.prev_playlists);
        entry.engaged = entry.curr_units ? Math.round((entry.active / entry.curr_units) * 100) : 0;
        entry.code = null;
        entry.vendors = VendorHelper.VendorColors(entry.vendors);
        entry.units_change = entry.prev_units ? entry.curr_units - entry.prev_units : 0;
        return entry;
    });
    return {data, total};
}

function _formatAutocompleteItem(hit){
    if(hit.entity)
        return hit;

    const entity = 'imprints',
        name = hit.name? hit.name : hit._source.name,
        id = hit.id ? hit.id : hit._id;
    
    return {
        id,
        entity,
        name: `${name} (imprint)`,
        logo: icons.entities[entity]
    }
}

function formatAutocompleteItem(hit){
    const entity = 'imprints';
    const silo = SiloSuffix(hit.data_silo_code);
    return {
        id: hit.id,
        entity,
        name: `${hit.name} ${silo}(imprint)`,
        name_raw: hit.name,
        logo: icons.entities[entity],
        image: ImageURL(hit.logo_file_name, hit.id, 'imprints')        
    }
}


function formatAutocomplete(data) {
    let {imprints}= data;
    return imprints.map(formatAutocompleteItem);
}

function formatDetails(data){
    if(!data)
        return [];

    let labels = [],
        dataset = [];
    
    data = data.sort(sortDateRange);
    
    for(let item of data) {
        labels.push(formatChartLabel(item));
        dataset.push(item.units);
    }
    return {labels, datasets: [{data: Object.values(dataset), label: 'plays'}]};
}

function formatCard(data) {
    data = data.data;
    return {
        id: data.id,
        name: `${data.name} (imprint)`,
        name_raw: `${data.name}`,
        logo: ImageURL(data.logo_file_name, data.id, 'imprints')
    }
}

function formatStreamsStats(streams, metadata) {
    let labels = [],
        datasets = [];
    if(streams && metadata) {
        const {dates, period} = dateRangeFlat(streams);
        datasets = metadata.map((imprint, index)=>{
            let data = [];
            for(let date of dates) {
                let stream = find(streams, {stream_date: date, imprint_id: imprint.id});
                data.push(stream ? stream.curr_units : null);
            }    
            const silo = SiloSuffix(imprint.data_silo_code);

            return {
                id: imprint.id,
                label: `${imprint.name} ${silo}(imprint)`,
                data,
                fill: false,
                borderColor: colors[index%colors.length],
                backgroundColor: colors[index%colors.length],
                pointBorderColor: colors[index%colors.length],
                pointBackgroundColor: colors[index%colors.length],                

            }
        });    
        
        labels =  map(dates, (date)=>formatChartLabelItem(date, period));
    }
    return { labels, datasets };
}

function formatMetadata(entities, metadata) {
    for(let entity of entities) {
        const metaEntity = find(metadata, {id: entity.id});
        if(metaEntity) {
            const silo = SiloSuffix(metaEntity.data_silo_code);
            const name = `${metaEntity.name} ${silo}`;
            entity.name = entity.imprint_name = name;
            entity.image = ImageURL(metaEntity.logo_file_name, entity.id, 'imprints');
        }
    }
    return entities;

}

function formatMetadataFlat(metadata) {
    let result = []
    for(let row of metadata) {
        const silo = SiloSuffix(row.data_silo_code);
        result.push({
            id: row.id,
            entity: 'imprints',
            name: `${row.name} ${silo}`,
            releaseDate: row.first_release_date,
            image: ImageURL(row.logo_file_name, row.id, 'imprints')
        });
    }
    return result;
}

function formatTimeseries(imprints, metadata) {
    for(let imprint of imprints) {
        const metaEntity = find(metadata, {id: imprint.imprint_id});
        if(metaEntity) {
            imprint.name = metaEntity.name;
        }
        imprint.stms_by_date = imprint.totals;
    }
        
    return imprints;
}